<template>
  <v-container>
    <v-form ref="form">
      <h2>{{ $t('forms.changeFileCategoryForm') }}</h2>
      <h4>{{ titleString }}</h4>
      <v-autocomplete
        dense
        v-model="selectedFileCategory"
        :items="translatedFileCategories"
        :label="$t('wizard.selectNewFileCategory') + '*'"
        :error-messages="selectFileCategoryErrors"
        @blur="$v.selectedFileCategory.$touch()"
        @input="$v.selectedFileCategory.$touch()"
      >
      </v-autocomplete>
      <v-btn color="success" class="ma-4" @click="changeFileCategory">{{
        $t('buttons.changeFileCategory')
      }}</v-btn>
      <!-- v-if="!client" -->
      <v-btn color="warning" class="ma-4" @click="$emit('toggle')">{{
        $t('buttons.cancel')
      }}</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { findFileCategoryTranslation } from '../store/helpers'

export default {
  props: {
    fileCategories: {
      type: Array,
      default: () => [],
    },
    file: {
      type: Object,
      default: () => {},
    },
  },
  validations: {
    selectedFileCategory: {
      required: required,
    },
  },
  data() {
    return {
      selectedFileCategory: null,
    }
  },

  computed: {
    selectFileCategoryErrors() {
      const errors = []
      if (!this.$v.selectedFileCategory.$dirty) return errors
      !this.$v.selectedFileCategory.required &&
        //TODO:
        errors.push(this.$i18n.t('validations.newFileCategoryIsRequired'))
      return errors
    },
    translatedFileCategories() {
      return this.fileCategories.map((fc) => {
        return {
          text: findFileCategoryTranslation(fc),
          value: fc,
        }
      })
    },

    titleString() {
      return (
        this.$i18n.t('title.file') +
        ': ' +
        this.file.name +
        '.' +
        this.file.extension
      )
    },
  },
  methods: {
    changeFileCategory() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$store.dispatch('file/changeCategory', {
          id: this.file.id,
          category: this.selectedFileCategory,
        })
        this.$refs.form.reset()
        this.$emit('toggle')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
