<template>
  <div>
    <v-carousel v-model="currentIndex">
      <v-carousel-item
        v-for="image in imageFilesData"
        :key="image.id"
        reverse-transition="fade-transition"
        transition="fade-transition"
        :src="imageSrc(image.id)"
        contain
      >
        <v-row class="fill-height" align="start" justify="center">
          <div class="text mt-2">{{ image.name }}</div>
        </v-row>
        <!-- <v-sheet :color="color" height="100%" tile>
          <v-row class="fill-height" align="center" justify="center">
            <div class="text-h2">Slide {{ i + 1 }}</div>
          </v-row>
        </v-sheet> -->
      </v-carousel-item>
    </v-carousel>
    <!-- <v-img
      class="text-center mx-auto"
      v-if="currentIndex"
      max-height="800"
      max-width="400"
      contain
      :src="imageFilesData[currentIndex].data"
      alt="Image preview"
    /> -->
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      currentIndex: null,
      imageFilesData: this.imageFiles,
    }
  },
  props: {
    imageFileIndex: {
      type: [String, Number],
    },
    show: {
      type: Boolean,
    },
    imageFiles: {
      type: Array,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.currentIndex = this.imageFileIndex

      // if (this.currentIndex) this.getImages(this.currentIndex)
    })
  },
  methods: {
    getImages(index) {
      this.$nextTick(() => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${this.imageFiles[index].id}/download`, // File URL Goes Here
          method: 'GET',
          responseType: 'blob',
        }).then((res) => {
          this.imageFilesData[index].data = window.URL.createObjectURL(res.data)
        })
        if (index != 0) {
          if (!this.imageFilesData[index - 1].data) {
            axios({
              url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${
                this.imageFiles[index - 1].id
              }/download`, // File URL Goes Here
              method: 'GET',
              responseType: 'blob',
            }).then((res) => {
              this.imageFilesData[index - 1].data = window.URL.createObjectURL(
                res.data
              )
            })
          }
        }
      })
    },
    imageSrc(id) {
      return `${process.env.VUE_APP_API_BASE_URL}/api/v1/files/${id}/download`
    },
  },
  watch: {
    imageFileIndex() {
      if (this.imageFileIndex) this.currentIndex = this.imageFileIndex
    },
    show() {
      if (this.show && this.currentIndex) {
        this.currentIndex = this.imageFileIndex
        // this.getImages(this.currentIndex)
      }
    },
  },
  imageFileIndex() {
    this.currentIndex = this.imageFileIndex
  },
}
</script>

<style lang="scss" scoped></style>
