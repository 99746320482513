<template>
  <v-card elevation="3" v-if="stage">
    <v-card-title class="justify-center"
      >{{ $t('wizard.stage') }}:
      <b> {{ $t(`stages["${stage.displayName}"]`) }}</b>
    </v-card-title>
    <StageReminder v-if="stage && !isClient" :latestReminder="latestReminder" />
    <v-card-actions class="justify-space-between">
      <!-- <v-btn
        @click="$emit('changeStage', stage.previousStageId)"
        :disabled="stageById(stage.previousStageId) == null"
      >
        <v-icon>mdi-arrow-left-circle</v-icon>
        {{
          stageById(stage.previousStageId)
            ? stageById(stage.previousStageId).displayName
            : ''
        }}
      </v-btn> -->
      <v-col v-if="!isClient" cols="3">
        <v-select
          dense
          :items="previousStagesById(stage.id)"
          :label="$t('wizard.selectPreviousStage')"
          item-text="displayName"
          item-value="id"
          v-model="previousSelectedStage"
          prepend-icon="mdi-arrow-left-circle"
          @click:prepend="changePreviousStage"
        ></v-select>
      </v-col>
      <v-col v-if="!isClient" cols="3">
        <v-select
          dense
          :items="nextStages"
          :label="$t('wizard.selectNextStage')"
          item-text="displayName"
          item-value="id"
          v-model="selectedStage"
          append-outer-icon="mdi-arrow-right-circle"
          @click:append-outer="changeStage"
        ></v-select>
      </v-col>

      <!-- <v-btn
        @click="$emit('changeStage', selectedStage)"
        :disabled="selectedStage == ''"
        v-if="selectedStage != ''"
      >
        {{ stageById(selectedStage).displayName }}
        <v-icon>mdi-arrow-right-circle</v-icon>
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import StageReminder from './StageReminder.vue'

export default {
  components: {
    StageReminder,
  },
  props: {
    stage: {
      type: Object,
      default: null,
    },
    latestReminder: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedStage: '',
      previousSelectedStage: '',
    }
  },
  methods: {
    changePreviousStage() {
      if (this.previousSelectedStage != '') {
        this.$emit('changeStage', this.previousSelectedStage)
      }
    },
    changeStage() {
      if (this.selectedStage != '') {
        this.$emit('changeStage', this.selectedStage)
      }
    },
  },
  computed: {
    ...mapGetters({
      stagesById: 'stage/stagesById',
      stageById: 'stage/stageById',
      previousStagesById: 'stage/previousStagesById',
      nextStagesById: 'stage/nextStagesById',
    }),
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    nextStages() {
      return this.nextStagesById(this.stage.id)
    },
  },
}
</script>

<style lang="scss" scoped></style>
