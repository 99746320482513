<template>
  <v-container>
    <v-form>
      <h1>{{ $t('forms.clientForm') }}</h1>
      <v-text-field
        dense
        v-model="formData.name"
        :label="$t('forms.companyName') + '*'"
        clearable
      >
      </v-text-field>
      <v-text-field
        dense
        :label="$t('forms.email') + '*'"
        v-model="formData.email"
        :error-messages="emailErrors"
        clearable
        @blur="$v.formData.email.$touch()"
        @input="$v.formData.email.$touch()"
      >
      </v-text-field>
      <v-text-field
        dense
        :label="$t('forms.phone') + '*'"
        clearable
        v-model="formData.phone"
      >
      </v-text-field>
      <v-row>
        <v-col>
          <v-text-field
            dense
            :label="$t('forms.address')"
            clearable
            v-model="formData.address"
          >
          </v-text-field>

          <v-text-field
            dense
            :label="$t('forms.registrationNumber')"
            clearable
            v-model="formData.registrationNumber"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        dense
        :label="$t('forms.taxNumber')"
        v-model="formData.taxNumber"
        clearable
      ></v-text-field>

      <h2 class="text-left">{{ $t('forms.bankInfo') }}</h2>
      <v-text-field
        dense
        :label="$t('forms.account')"
        v-model="formData.iban"
        clearable
      ></v-text-field>
      <v-text-field
        dense
        :label="$t('forms.bank')"
        clearable
        v-model="formData.bank"
      ></v-text-field>
      <v-text-field
        dense
        :label="$t('forms.swift')"
        clearable
        v-model="formData.bic"
      ></v-text-field>
      <v-divider></v-divider>
      <v-btn color="success" class="ma-4" @click="submit">{{
        $t('buttons.save')
      }}</v-btn>
      <v-btn
        v-if="!client"
        color="warning"
        class="ma-4"
        @click="$emit('toggle')"
        >{{ $t('buttons.cancel') }}</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
import { minLength, required, email } from 'vuelidate/lib/validators'
const plusInFront = (value) => value != null && value.indexOf('+') == 0

export default {
  props: {
    clientId: {
      type: [Number, String],
      required: false,
    },
    client: {
      type: Object,
      required: false,
    },
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
      },
      phone: {
        required: false,
        plusInFront,
      },
    },
  },
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        email: '',
        address: '',
        registrationNumber: '',
        taxNumber: '',
        bank: '',
        iban: '',
        bic: '',
      },
    }
  },

  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.formData.email.$dirty) return errors
      !this.$v.formData.email.email &&
        errors.push(this.$i18n.t('validations.emailValid'))
      !this.$v.formData.email.required &&
        errors.push(this.$i18n.t('validations.emailRequired'))
      return errors
    },
  },
  methods: {
    submit() {
      if (
        this.formData.name != '' &&
        this.formData.email != '' &&
        this.formData.phone != ''
      ) {
        this.$emit('saveClient', this.formData)
      }
    },
  },

  created() {
    if (this.client) this.formData = this.client
  },
  watch: {
    client() {
      this.formData = this.client
    },
  },
}
</script>

<style lang="scss" scoped></style>
