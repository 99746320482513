<template>
  <div v-if="latestReminder">
    {{ $t('wizard.nextUpdateScheduled') }} {{ formattedDate }}
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    latestReminder: {
      type: Object,
    },
  },
  computed: {
    formattedDate() {
      if (this.latestReminder) {
        return dayjs(this.latestReminder.triggerAt).format('YYYY-MM-DD HH:mm')
      } else {
        return 'N/A'
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
