<template>
  <v-card>
    <v-card-text>
      <v-row align="center">
        <v-subheader inset>{{ categoryLabel(category) }}</v-subheader>
      </v-row>
      <v-row align="center">
        <v-checkbox
          v-if="!isClient"
          dense
          label=""
          :value="categoryRequired"
          @change="requireCategory"
        ></v-checkbox>
        <v-file-input
          :clearable="false"
          :error-messages="errorMessages"
          dense
          accept="*"
          truncate-length="15"
          :label="categoryLabel(category)"
          v-model="currentFiles"
          @change="selectFiles"
          multiple
          small-chips
          :loading="loading"
        >
          <template slot="progress">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              bottom
              color="deep-purple accent-4"
            ></v-progress-linear>
          </template>
        </v-file-input>
        <div
          class="drop-zone"
          :class="{ 'drag-over': isDragOver }"
          @dragover="handleDragOver"
          @dragleave="handleDragLeave"
          @drop="handleDrop"
        >
          {{ $t('fileUpload.dropFilesHere') }}
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// import { throwStatement } from '@babel/types'
import FileService from './../services/FileService'
import { findFileCategoryTranslation } from '../store/helpers'

export default {
  data: function () {
    return {
      currentFiles: [],
      progress: 0,
      message: '',
      fileInfos: [],
      loading: false,
      isDragOver: false,
    }
  },
  props: {
    category: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    requiredFileCategories: {
      type: Array,
      default: () => [],
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    requireCategory(value) {
      let payload = {
        value,
        category: this.category,
      }
      this.$emit('handleRequiredCategories', payload)
    },
    translateFileCategory(text) {
      return findFileCategoryTranslation(text)
    },

    handleDragOver(event) {
      // Prevent default behavior to allow dropping files
      event.preventDefault()
      this.isDragOver = true
    },
    handleDragLeave(event) {
      event.preventDefault()
      this.isDragOver = false
    },
    handleDrop(event) {
      // Prevent default behavior to avoid opening the file in the browser
      event.preventDefault()

      // Access the dropped files from the event
      const files = Array.from(event.dataTransfer.files)
      this.selectFiles(files)
    },
    selectFiles(files) {
      this.loading = true
      this.progress = 0
      this.currentFiles = files
      this.currentFiles.forEach((f) => (f.category = this.category))
      // this.currentFile.category = this.category
      // this.$emit('handleUploadedFile', 3)
      this.upload()
    },
    upload() {
      if (this.currentFiles.length == 0) {
        this.message = 'Please select files!'
        return
      }
      this.message = ''
      this.currentFiles.forEach((f) => {
        FileService.uploadFile(f, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
          .then((response) => {
            if (response.data?.file)
              this.$emit('handleUploadedFile', response.data.file.id)
          })
          .then(() => {
            this.loading = false
          })
          .catch(() => {
            this.progress = 0
            this.message = this.$i18n.t('dialogMessages.couldNotUploadTheFile')
            this.currentFiles = this.currentFiles.filter(
              (file) => file.name !== f.name
            )
          })
      })
    },
    categoryLabel(category) {
      let label = ''
      switch (category) {
        case 'Carrier‘s CMR waybill':
          label = `${this.translateFileCategory(category)}*`
          break
        default:
          label = `${this.translateFileCategory(category)}`
      }
      return label
    },
  },
  computed: {
    categoryRequired() {
      return this.requiredFileCategories.includes(this.category)
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
  },
}
</script>

<style lang="scss" scoped>
.drop-zone {
  width: 100%;
  height: 30px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.drop-zone.drag-over {
  border-color: #ec7b29; /* Change border color */
  transform: scaleY(1.1); /* Slightly scale up the drop zone */
}
</style>
