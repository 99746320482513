<template>
  <v-row justify="center">
    <v-dialog v-model="reminderDialog" width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
          <v-icon v-if="!latestReminder" color="orange darken-2"
            >mdi-calendar-clock</v-icon
          >
          <v-icon v-else color="orange darken-2" @click="setReminderValues"
            >mdi-pencil</v-icon
          >
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('stageReminder.claimReminder') }}</span>
        </v-card-title>
        <v-card-actions>
          <v-menu
            v-model="reminder_datepicker_show"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="reminderDate"
                :label="$t('stageReminder.reminderDate')"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="reminderDate"
              @input="reminder_datepicker_show = false"
            >
            </v-date-picker>
          </v-menu>
          <v-menu
            v-model="reminder_timepicker_show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
            max-width="290px"
            @input="
              (value) =>
                value && $refs.picker && ($refs.picker.selectingHour = true)
            "
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="reminderTime"
                :label="$t('stageReminder.reminderTime')"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-time-picker
              ref="picker"
              format="24hr"
              v-model="reminderTime"
              scrollable
              no-title
              @click:minute="reminder_timepicker_show = false"
            ></v-time-picker>
          </v-menu>
        </v-card-actions>
        <v-card-actions>
          <v-text-field
            v-model="reminderMessage"
            :label="$t('stageReminder.message')"
            clearable
            dense
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!latestReminder"
            :disabled="!reminderDateCheck"
            color="green darken-1"
            text
            @click="fireReminder"
          >
            {{ $t('stageReminder.setReminder') }}
          </v-btn>
          <v-btn
            v-else
            :disabled="!reminderDateCheck"
            color="green darken-1"
            text
            @click="updateReminder"
          >
            {{ $t('stageReminder.updateReminder') }}
          </v-btn>
          <v-btn color="green darken-1" text @click="reminderDialog = false">
            {{ $t('stageReminder.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
export default {
  props: {
    latestReminder: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      reminderDialog: false,
      reminder_datepicker_show: false,
      reminder_timepicker_show: false,
      reminderDate: '',
      reminderTime: '',
      reminderMessage: '',
      updatedReminder: null,
    }
  },
  computed: {
    reminderDateCheck() {
      if (this.reminderDate == '') {
        return false
      } else {
        let today = Date.now()
        let dateDiff = dayjs(this.reminderDate).diff(dayjs(today), 'day')
        if (dateDiff < 0) {
          return false
        } else {
          return true
        }
      }
    },
    ...mapState({
      claim: (state) => state.mtpl.claim,
    }),
  },
  methods: {
    setReminderValues() {
      if (!this.updatedReminder) {
        this.reminderMessage = this.latestReminder.message
        this.reminderDate = dayjs(this.latestReminder.triggerAt).format(
          'YYYY-MM-DD'
        )
        this.reminderTime = dayjs(this.latestReminder.triggerAt).format('HH:mm')
      } else {
        this.reminderMessage = this.updatedReminder.message
        this.reminderDate = dayjs(this.updatedReminder.triggerAt).format(
          'YYYY-MM-DD'
        )
        this.reminderTime = dayjs(this.updatedReminder.triggerAt).format(
          'HH:mm'
        )
      }
    },
    updateReminder() {
      let reminderData = {
        id: this.latestReminder.id,
        message: this.reminderMessage,
        claimId: this.claim.id,
        triggerAt: this.combineDateAndTime(),
        triggered: false,
      }
      this.$store
        .dispatch('reminder/updateReminder', reminderData)
        .then((response) => {
          this.updatedReminder = response
          this.reminderDialog = false
        })
    },
    fireReminder() {
      //"2022-08-10T09:06:00Z"
      let reminderData = {
        message: this.reminderMessage,
        claimId: this.claim.id,
        triggerAt: this.combineDateAndTime(),
      }
      this.$store.dispatch('reminder/createReminder', reminderData).then(() => {
        this.reminderDate = ''
        this.reminderTime = ''
        this.reminderMessage = ''
        this.reminderDialog = false
      })
    },
    combineDateAndTime() {
      if (this.reminderTime != '' && this.reminderDate != '') {
        let [hours, minutes] = this.reminderTime.split(':')
        return new Date(
          dayjs(this.reminderDate).add(hours, 'h').add(minutes, 'm')
        )
      } else if (this.reminderDate != '') {
        return new Date(dayjs(this.reminderDate))
      }
    },
  },
  created() {
    this.reminderMessage = `${
      this.claim.claimNumber ? this.claim.claimNumber : this.claim.id
    } ${this.claim.stage.displayName}`
  },
}
</script>

<style lang="scss" scoped></style>
